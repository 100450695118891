.header {
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #131921;
    position: sticky;
    top: 0;
    z-index: 100;
}

.header__logo {
    width: 100px;
    object-fit: contain;
    margin: 0 20px;
    margin-top: 6px;
    align-items: center;
    border-color: #131921;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    padding: 6px;
}

.header__logo:hover {
    border-color: white;
}

.header__delivery {
    margin-top: 6px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    padding: 6px;
    border-color: #131921;
    
}

.header__delivery:hover {
    border-color: white;
}

.header__deliveryLogo {
    color: rgb(236, 234, 234);
}

.header__deliveryText {
    padding-left: 5px;
}

.header__deliveryTextLocation {
    color: rgb(236, 233, 233);
}

.header__deliveryTextName {
    color: rgb(150, 149, 149);
}

.header__search {
    display: flex;
    flex: 1;
    align-items: center;
    border-radius: 24px;
}

.header__searchInput {
    height: 12px;
    padding: 10px;
    border: none;
    width: 80%;
    border-radius: 5px 0 0 5px;
}

.header__searchInput:focus {
    box-shadow: 0 0 3px 3px #cd9042;
    outline-style: none;
}

.header__searchIcon {
    padding: 5px;
    height: 22px !important;
    background-color: blue;
}

.header__nav {
    display: flex;
    justify-content: space-evenly;
}

.header__nav > a {
    text-decoration: none;
}



.header__option {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    color: Orange;
    border-color: #131921;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    padding: 6px;
}

.header__option:hover {
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    padding: 6px;
    border-color: white;
}

.header__searchIcon {
    padding: 5px;
    height: 22px !important;
    background-color: #cd9042;
}

.header__optionLineOne {
    font-size: 10px;
}

.header__optionLineTwo {
    font-size: 13px;
    font-weight: 800;
}

.header__optionBasket {
    display: flex;
    align-items: center;
    color: orange;
    border-color: #131921;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    padding: 9px 5px;
}

.header__optionBasket:hover {
    border-color: orange;
}


.header__basketCount {
    margin-left: 10px;
    margin-right: 10px;
}

