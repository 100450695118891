.footer {
    display: flex;
    flex-direction: column;
}


.footer > button {
    width: 100%;
    height: 60px;
    color: Blue;
    background-color: rgb(55, 71, 90);
    cursor: pointer;
    margin-top: 15px;
    font-size: 18px;
}

.footer > button:hover {
    background-color: #4B596B;
}

.footer__container {
    background-color: rgb(35, 47, 62);
}

.footer__stuff {
    display: flex;
    justify-content: space-between;
    margin: 40px 80px 100px 80px;
    
}

.footer__title {
    cursor: pointer;
    color: Orange;
    font-size: 18px;
    margin-bottom: 10px;
}

.footer__text {
    cursor: pointer;
    color: rgb(208, 208, 208);
    font-size: 16px;
    padding: 5px;
}

.footer__text > h4 {
    margin-bottom: 12px;
}








